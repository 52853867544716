body {
  background-color:#dedede;
  overflow-x: hidden;
}
.bdr {
  border: solid 1px red;
}
.App {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  margin: auto auto;
}

.App-title {
  font-size: 2em;
  font-weight: normal;
}

.App-intro {
  font-size: large;
}

.warning {
  color: darken(red, 20%);
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
}
#keywordBox {
  min-width:120px;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.dd-logo {
  height: 150px;
}

.header-container {
  display: flex;
  width:100%;
  justify-content: center;
  margin:auto auto;
  margin-top: 0px;
  margin-bottom: 30px;
}
.header {
  display: flex;
}
.width100pc {
  margin:auto;
  height:89%;
  max-width:100%;
  position: relative;
}

.valign {
  display: table;
  width: 100%;
  height: 100%;
}
.valign > div {
  display: table-cell;
  width: 100%;
  height: 100%;
}
.valign.bottom > div {
  vertical-align: bottom;
  height: 100%;
}
.arrowDiv {
  position:absolute;
  bottom:0px;
  left:0px;
  cursor:pointer;
}
#white-arrow {
  fill: white;
}
.greyBG {
  width:100%;
  background-color:#dedede;
}
.padBorder {
  margin:40px;
}
.left {
  text-align:left;
  padding:20px;
}
.w100pc {
  width:100%;
}
.content-container {
  border:solid 0px #aaa;
  margin:50px;
  background-color: #efefef;
}
.pad20 {
  margin:10px;
  margin-left:10px;
  margin-right:10px;
  border-bottom:solid 0px #aaa;
}
.pad20 div {
  font-weight:bold;
}
.applyBtn {
  background-color:#1FBBA6;
  color:white;
  padding:5px;
  width:100%;
  font-weight:bold;
  font-size:22px;
  height:50px;
}
.waitCursor {
  cursor: not-allowed;
}
.proceedCursor {
  cursor: pointer;
}
.h50 {
  height:50px;
}
.view-area {
  border-top:solid 1px #aaa;
  background-color:white;
  min-height:350px;
}
.iB {
  float:left;
}
.flightNumber {
  font-size:30px;
  font-weight:normal;
  text-align:center;
  padding-top:30px;
  font-family: arial, helvetica;
  color:#777;
}
.standard {
  font-weight:normal;
  font-size:15px;
}
.linkBtn {
  border: solid 1px #ccc;
  padding:5px;
  background-color: #efefef;
  margin:5px;
  padding-left:30px;
  padding-right:30px;
  color:#666;
  min-width:150px;
}
.bdrBottom {
  border-bottom:solid 1px #eee;
  padding-bottom:30px;
}
.fnTxt {
  font-weight:normal;
  font-size:9px;
  text-align:center;
  font-family: arial, helvetica;
  color:#999;
}
.lnkbutton {
  min-width:150px;
}
select {
  min-width:100px;
  height:40px;
  -webkit-appearance: menulist-button;
}
.nr-size {
  height:250px;
}
.hdn {
  display:none;
}
.showme {
  display:inline;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #444;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

#spinner {
  position:absolute;
  z-index:10;
  margin-top:80px;
  margin-left:-5%;
  width:150px;
  height:150px;
}
.red-text {
  color:red;
}

@media (max-width: 1100px) { 
  .linebreak {
    display:inline-block;
    margin-left:7px;
  }
 }

 @media (max-width: 850px) {
  .content-container {
    border:solid 0px #aaa;
    margin:0px;
    background-color: #efefef;
  }
 }

@media (min-width: 1100px) { 
  .linebreak {
    display:inline-block;
  }
 }
 .mw500 {
   min-width:380px;
 }
 .baseLnk{
   cursor:pointer;
   text-decoration: underline;
 }

 .razar {
  float:left;
  position:relative;
  width:150px;
  height:150px;
  background-size: 100% 100%;
  border-radius:75px;
  box-shadow:0 1px 1px 0 rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2), inset 0 1px 1px 0 rgba(255,255,255,0.2), inset 0 2px 4px 1px rgba(255,255,255,0.1);
}

.pulse {
  position:absolute;
  top:0;
  left:0;
  width:150px;
  height:150px;
  border-radius:75px;
  -moz-animation: pulsating 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: pulsating 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  opacity:0.0;
  z-index:5;
}

.ringbase {
  position:absolute;
  top:0;
  left:0;
  width:150px;
  height:150px;
  border-radius:75px;
  opacity:0.0;
  z-index:10;
}

.ring1 {
  box-shadow:0 0 2px 1px #000, inset 0 0 2px 1px #000;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
}

.ring2 {
  box-shadow:0 0 1px 0px #888, inset 0 0 1px 0px #888;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 0.5s;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.5s;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}

@-webkit-keyframes pulsating {
  0% {opacity: 0.0;}
  50% {opacity: 0.2;}
  100% {opacity: 0.0;}
}

@-moz-keyframes pulsating {
  0% {opacity: 0.0;}
  50% {opacity: 0.2;}
  100% {opacity: 0.0;}
}

@keyframes pulsating {
  0% {opacity: 0.0;}
  50% {opacity: 0.2;}
  100% {opacity: 0.0;}
}

@-webkit-keyframes ring {
  0% {-webkit-transform: scale(0.4, 0.4); opacity: 0.0;}
  50% {opacity: 0.6;}
  100% {-webkit-transform: scale(1.1, 1.1); opacity: 0.0;}
}

@-moz-keyframes ring {
  0% {-moz-transform: scale(0.4, 0.4); opacity: 0.0;}
  50% {opacity: 0.6;}
  100% {-moz-transform: scale(1.1, 1.1); opacity: 0.0;}
}

@keyframes ring {
  0% {transform: scale(0.4, 0.4); opacity: 0.0;}
  50% {opacity: 0.6;}
  100% {transform: scale(1.1, 1.1); opacity: 0.0;}
}


.pointer {
  position: absolute;
  width: 150px;
  top: 75px;
  -webkit-animation: circling 2s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: circling 2s linear;
  -moz-animation-iteration-count: infinite;
  animation: circling 2s linear;
  animation-iteration-count: infinite;
  z-index: 20;
}

.pointer div {
  width: 49%;
  border-bottom:2px solid #000;
}

.dot {
  opacity: 0;
  border: 0px solid #72a8e5;
  border-radius: 100%;
  position:absolute;
  -webkit-animation: blink 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: blink 2s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: blink 2s ease-out;
  animation-iteration-count: infinite;
  z-index: 25;
}

.dot.pos1 {
  left:10px;
  top:38px;
}

.dot.pos2 {
  left:40px;
  top:18px;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes circling {
  0% {-webkit-transform: rotate(0deg);}
  50% {-webkit-transform: rotate(180deg);}
  100% {-webkit-transform: rotate(360deg);}
}

@-moz-keyframes circling {
  0% {-moz-transform: rotate(0deg);}
  50% {-moz-transform: rotate(180deg);}
  100% {-moz-transform: rotate(360deg);}
}

@keyframes circling {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}

@-webkit-keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 140px;
  height: 140px;
  background: #fff;
  overflow: hidden;
}
.card .imgBx,
.card .details {
  width: 100%;
  height: 100%;
  position: absolute;
  /* box-sizing: border-box; */
  transition: .5s;
}
.card .imgBx {
  top: 50;
  left: 0;
  margin-right:20px;
}
.card:hover .imgBx {
  top: 0;
  left: -100%;
}
.card .imgBx img {
  width: 100%;
  height: 100%;
  padding-left:5px;
}
.card .details {
  background: #fff;
  top: 0;
  left: 110%;
  padding: 10px 10px;
  font-weight:normal;
  font-size:12px;
  text-align: left;
  line-height:20px;
}
.card:hover .details {
  top: 0;
  left: 0%;
}
.card .details h2 {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0 0 10px;
  border-bottom: 2px solid #000;

}
.card .details p {
  margin: 10px 0 0;
  color: #000;
}

@import  url(https://fonts.googleapis.com/css?family=Montserrat);


$width: 15px;
$height: 15px;


$bounce_height: 30px;



.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:200px;
}

.text {
  color: #fbae17;
  display: inline-block;
  margin-left: 5px;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: $width;
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #fbae17;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}