.App {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin-left: 30px;
  margin-right: 30px; }

.App-title {
  font-size: 2em;
  font-weight: normal; }

.App-intro {
  font-size: large; }

.warning {
  color: #990000;
  font-weight: bold; }

body {
  margin: 0;
  padding: 0; }

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px; }

.dd-logo {
  height: 150px; }
